body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.last-day-of-week {
  /* background-color: #efefef; */
  border-top: 3px double #ccc;
}

.ant-table-content table {
  border-collapse: collapse !important;
}

.gray-1 {
  background-color: #fff;
}
.gray-2 {
  background-color: #f8f8f8;
}
.gray-3 {
  background-color: #f6f6f6;
}

.ant-btn {
  display: inline-flex !important;
  align-items: center !important;
}
